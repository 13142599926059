
<template>
      <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section 2 <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >
                 <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 2: Homeowner Info
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    style="width:50%;"
                                    label="Date"
                                    color="black"
                                    type="date"
                                    v-model="section.column_1"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field
                                    label="Revision #1 Date"
                                    type="date"
                                    color="black"
                                    v-model="section.column_2"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field
                                    label="Revision #2 Date"
                                    type="date"
                                    color="black"
                                    v-model="section.column_3"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field
                                    label="Revision #3 Date"
                                    type="date"
                                    color="black"
                                    v-model="section.column_4"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 1 - First Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 1 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                    label="Homeowner 1 - Cell#"
                                    color="black"
                                    v-model="this.selected_user.CellNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                    label="Homeowner 1 - Email"
                                    color="black"
                                    v-model="this.selected_user.PrimaryEmailAddress"
                                />
                            </v-col>


                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 2 - First Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 2 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                    label="Homeowner 2 - Cell#"
                                    color="black"
                                    v-model="this.selected_user.HomeNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                    label="Homeowner 2 - Email"
                                    color="black"
                                    v-model="this.selected_user.SecondaryEmailAddress"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            >
                                <v-text-field
                                    label="Address & Legal"
                                    color="black"
                                    v-model="this.selected_user.Address"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Projected Closing Date"
                                    type="date"
                                    color="black"
                                    v-model="selected_user.BuildEnd"
                                />
                            </v-col>
                          <v-col col="12" md="12">
                                <v-header><strong>Additional Information/Notes</strong><v-text-field v-model="section.column_5"/></v-header>
                            </v-col>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>

                <base-material-card>
                    <template v-slot:heading>
                         <div class="display-2 font-weight-light">
                        Section 2: Interior
                        </div>
                        <div class="display-2 font-weight-light">
                        North Metro Supplies
                        </div>
                         <div class="display-2 font-weight-light">
                        View all items listed at: <a href='www.northmetrosupplies.com' target='_blank'>www.northmetrosupplies.com</a>
                        </div>
                    </template>
                    <v-form>
                        <v-container class="py-0">
                            <v-row>
                                
                                <v-col cols="12">
                                  <v-header><strong>INTERIOR DOORS: </strong> STANDARD WHITE PRE-PAINTED MASONITE HOLLOW CORE 3 PANEL,</v-header>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-subheader>OPTIONAL DOORS</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-select :items="['Yes','No', 'Undecided']" item-value=" " v-model="section.column_6"></v-select>
                                </v-col>

                                <v-col cols="12" md="1">
                                    <v-subheader>STYLE:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="section.column_7"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-subheader>CLOSET DOORS:  BI FOLD:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_8"></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-subheader>CLOSET DOORS OPTIONAL: SWING</v-subheader>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_9"></v-select>
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-subheader>DOUBLE (FRENCH) DOORS: </v-subheader>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_10"></v-select>
                                </v-col>
                              <v-col cols="12" md="1">
                                    <v-subheader>LOCATIONS:</v-subheader>
                                </v-col>
                              <v-col cols="12" md="2">
                                    <v-text-field v-model="section.column_11"/>
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-subheader>Stainable</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_12"></v-select>
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-subheader>Paint Grade</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="2">
                                   <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_13"></v-select>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-subheader>GLASS WITH GRIDS:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_14"></v-select>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-subheader>PANTRY DOOR (KIT)</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-select :items="['Stainable','Paint Grade', 'STD. White Hollow Core 3 Panel','Undecided']" v-model="section.column_15"></v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-subheader>INTERIOR MILL WORK PACKAGE STANDARD POPLAR:</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_16"></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-subheader>OPTIONAL CASING (MAIN LEVEL)</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['MC5','Undecided']" v-model="section.column_17"></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-subheader>OPTIONAL BASE (MAIN LV.)</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['F206','MB21','Undecided']" v-model="section.column_18"></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-subheader>OPTIONAL CASING (UPSTAIRS)</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['MC5','Undecided']" v-model="section.column_19"></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-subheader>OPTIONAL BASE (UPSTAIRS)</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="3">
                                     <v-select :items="['F206','MB21','Undecided']" v-model="section.column_20"></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-subheader>OPTIONAL CASING (BASEMENT)</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['MC5','Undecided']" v-model="section.column_21"></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-subheader>OPTIONAL BASE (BASEMENT)</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['F206','MB21','Undecided']" v-model="section.column_22"></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-subheader>OPTIONAL DOOR TOPPERS (MAIN LEVEL)</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['MC5(std)','T500','Undecided']" v-model="section.column_23"></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-subheader>(UPSTAIRS)</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['MC5(std)','T500','Undecided']" v-model="section.column_24"></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-subheader>(BASEMENT)</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['MC5(std)','T500','Undecided']" v-model="section.column_25"></v-select>
                                </v-col>

                                <v-col cols="12">
                                    <v-header>(Go to ABOVE WEBSITE & click on MILLWORK, click on HERITAGE MILLWORK INC., click on PRODUCTS, click on STAIR PARTS)</v-header>
                                </v-col>
                              <div>
                                <v-spacer/>
                              <v-divider></v-divider>
                              </div>
                                <v-col cols="12" >
                                    <v-header><strong>BALUSTERS</strong></v-header>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-subheader>IRON (HOLLOW):</v-subheader>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_26"></v-select>
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-subheader>OPTIONAL:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="3">
                                   <v-select :items="['H0KNUC44','H1KNUC44', 'H2KNUCC44','Undecided']" v-model="section.column_27"></v-select>
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-subheader>WOOD</v-subheader>    
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['Yes','No', 'Popular','Undecided']" v-model="section.column_28"></v-select>
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-subheader>OPTIONAL:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-text-field v-model="section.column_73"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-subheader>NEWEL POST - STANDARD WOOD:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_29"></v-select>
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-subheader>OPTIONAL:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-text-field v-model="section.column_30"/>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <p>STANDARD MATCHING WOOD RAILING EACH FINISHED STAIRWAY</p>    
                                </v-col>

                                <v-col cols="12" >
                                  <v-header><strong>DOOR KNOBS</strong></v-header>
                                  </v-col>
                              <v-col cols="12" md="4">
                                    <v-subheader>EXTERIOR FRONT DOOR: STANDARD KWIKSET:</v-subheader>
                                </v-col>
                                  <v-col
                                      cols="5" md="3">
                                    <v-select :items="['Venetian Bronze', 'Satin Nickel', 'Undecided']" v-model="section.column_31"></v-select>
                                  </v-col>
                              <v-col cols="12" md="1">
                                    <v-subheader>OPTIONAL:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="section.column_32"/>
                                </v-col>
                              <v-col cols="12" md="3">
                                    <v-subheader>INTERIOR: STANDARD KWIKSET:</v-subheader>
                                </v-col>
                                  <v-col
                                      cols="4" md="4">
                                    <v-select :items="['Venetian Bronze', 'Satin Nickel', 'Undecided']" v-model="section.column_33"></v-select>
                                  </v-col>
                              <v-col cols="12" md="1">
                                    <v-subheader>OPTIONAL:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="section.column_34"/>
                                </v-col>
                              <v-col cols="12" md="3">
                                    <v-subheader>GARAGE TO HOUSE: STANDRD KWIKSET:</v-subheader>
                                </v-col>
                                <v-col
                                      cols="4">
                                  <v-select :items="['Venetian Bronze', 'Satin Nickel', 'Undecided']" v-model="section.column_35"></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="3">
                                    <v-subheader>GARAGE SERVICE DOOR:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="section.column_36"/>
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-subheader>N/A</v-subheader>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_37"></v-select>
                                </v-col>
                              </v-row>
                          <v-row>
                                <v-col cols="12" >
                                  <v-header><strong>DOOR HINGES</strong></v-header>
                                </v-col>
                            <v-col cols="12" md="1">
                                    <v-subheader>STANDARD:</v-subheader>
                                </v-col>
                                <v-col
                                      cols="3">
                                  <v-select :items="['Venetian Bronze', 'Satin Nickel', 'Undecided']" v-model="section.column_38"></v-select>
                                </v-col>
                            <v-col cols="12" md="1">
                                    <v-subheader>OPTIONAL:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="7">
                                    <v-text-field v-model="section.column_39"/>
                                </v-col>

                                <v-col cols="12" md="4" >
                                  <v-header><strong>CLOSET:</strong>SHELVING: STANDARD = MELAMINE SHELVING:</v-header>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_40"></v-select>
                                </v-col>

                                <v-col cols="12" md="4" >
                                  <v-header><strong>LINEN CLOSET:</strong>STANDARD = MELAMINE SHELVING:</v-header>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_41"></v-select>
                                </v-col>

                                <v-col cols="12" md="4" >
                                  <v-header><strong>WALK-IN PANTRY:</strong> STANDARD = MELAMINE SHELVING</v-header>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_42"></v-select>
                                </v-col>
                                <v-col col="12" md="12">
                                <v-header><strong>Notes</strong><v-textarea v-model="section.column_43"/></v-header>
                                 </v-col>

                                <v-col cols="12" md="4" >
                                    <v-header>SWITCH PLATES AND OUTLET COVERS:</v-header>   
                                </v-col>
                                <v-col cols="12" md="2">
                                     <v-select :items="['STD White', 'Undecided']" v-model="section.column_44"></v-select>
                                </v-col>

                                <v-col cols="12" >
                                    <v-header>
                                      <h3>INTERIOR PAINT: (SEE SECTION H)</h3>
                                      <p>STANDARD PAINTING PROCEDURE IS 2 COATS OF <strong>SHERWIN WILLIAMS</strong> MATTE FINISH YOUR COLOR CHOICE. THIS ALLOWS FOR EASY TOUCH UP FOR THE 1YEAR CHECK UP. OR...IF YOU CHOOSE ANOTHER SHEEN/FINISH WE WILL HAVE TO GET PRICING ON A BID BASIS FOR YOUR SPECIFIC HOUSE PLAN. (TIME FRAME - 4 WEEKS)</p>
                                      <p><strong>INTERIOR STAIN:</strong> (SEE SECTION H) SELECTED AT SUPERIOR FLOORING (TIME FRAME - 4 WEEKS)</p>
                                      <p><strong>LIGHTING:</strong> (GIVE 43RD STREET LIGHTING INC. SHEET) (TIME FRAME -3 WKS)(SEE SECTION C)</p>
                                      <p><strong>RECESSED LIGHTS:</strong> IF YOUR HOUSE PLAN PROVIDES RECESSED LIGTING, LOCATIONS WILL BE DISCUSSED AT ELECTRICAL MEETING AT JOB SITE.</p>

                                    </v-header>   
                                </v-col>

                                <v-col cols="12" md="3" >
                                  <v-header><strong>SPACE SAVER MICROWAVE:</strong></v-header>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select :items="['Standard','Non-Vented', `(Optional) Vented Hoods ($${this.price_section_two.column_8})`,'Undecided']" v-model="section.column_58"></v-select>
                                </v-col>
                            </v-row>
                                <v-row>
                                <v-col cols="12" md="3" >
                                  <v-header><strong>DRYER:</strong> STANDARD: ELECTRIC</v-header>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_45"></v-select>
                                </v-col>
                                <v-col cols="12" md="2" >
                                    <v-subheader>(OPTIONAL): GAS</v-subheader>   
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_46"></v-select>
                                </v-col>
                            <v-col cols="12" md="2" >
                                    <v-subheader>(${{this.price_section_two.column_9}} UPGRADE ABOVE STD.)</v-subheader>
                                </v-col>
                                  </v-row>
                            <v-row>
                                <v-col cols="12" md="3" >
                                  <v-header><strong>STOVE:</strong> STANDARD: ELECTRIC </v-header>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_47"></v-select>
                                </v-col>
                                <v-col cols="12" md="2" >
                                    <v-subheader>(OPTIONAL): GAS</v-subheader>   
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_48"></v-select>
                                </v-col>
                                <v-col cols="12" md="2" >
                                    <v-subheader>(${{this.price_section_two.column_10}} UPGRADE ABOVE STD.)</v-subheader>
                                </v-col>
                          </v-row>
                          <v-row>
                                <v-col cols="12" md="2" >
                                  <v-header><strong>WASHING MACHINE:</strong></v-header>
                                </v-col>
                                <v-col cols="12" md="1" >
                                    <v-subheader>LOCATION:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select :items="['To The Left','To The Right', 'Undecided']" v-model="section.column_49"></v-select>
                                </v-col>
                                <v-col cols="12" md="1" >
                                    <v-subheader>LOAD:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="2">
                                   <v-select :items="['Top Load','Front Load', 'Pedestals','Undecided']" v-model="section.column_50"></v-select>
                                </v-col>
                          </v-row>
                            <v-row>

                                <v-col cols="12"  >
                                    <h4>APPLIANCES: MJ’S CONTRACT APPLIANCES, 6922 55TH ST. N., OAKDALE, 55128, 651-777-8915,</h4>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-subheader>ALLOWANCE:</v-subheader>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <v-text-field label="(TIME FRAME - IMMEDIATELY AFTER ULTIMATE CABINET MEETING)" v-model="section.column_51"/>
                                </v-col>

                                <v-col cols="12"  >
                                  <v-subheader><strong>CABLE TV LOCATION CHOICE (4):</strong> TO BE SELECTED AT ELECTRICAL WALK THRU:*SEE SEPARATE ELECTRICAL MEETING PACKET*</v-subheader>
                                </v-col>
                            <v-col col="12" md="12">
                                <v-header><strong>Notes</strong><v-textarea v-model="section.column_52"/></v-header>
                            </v-col>
                      <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-col  cols="12" class="text-right">
                        <div v-if="permissions.scope === 'create:users,fullaccess:staff'"
                        class="text-right">
                        <v-btn
                          color="primary"
                          dark
                          class="text-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Submit
                        </v-btn>
                        </div>
                        </v-col>
                      </template>
                      <v-card>
                        <v-card-title>Add Vendor Email</v-card-title>
                        <v-card-actions>
                        <v-card-text>
                          <v-container>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>
                                <v-col
                                     cols="12"
                                     md="4">

                                  <v-select
                                      v-model ="add_vendor_email"
                                     label="Add Vendor Email"
                                     :items="['Yes', 'No']"
                                      @change="add_vendor_email"
                                ></v-select>
                                 </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        </v-card-actions>
                      </v-card>
                             <v-expand-transition>
                          <v-card
                          v-if="add_vendor_email === 'Yes'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                            <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>

                               <!-- Partner 1 -->

                               <v-col
                                cols="12"
                                md="13"
                                class="card-title font-weight-light pt-6">
                                Vendor Email
                               </v-col>
                                <v-autocomplete
                                ref="user"
                                :items="vendor_email"
                                v-model="selected_vendor.Email"
                                item-text="Email"
                                label="Vendor Email"
                                prepend-icon="mdi-account-search"
                                outlined
                                solo
                                menu-props="auto"
                                class="px-3"
                                required
                                return-object
                                ></v-autocomplete>
                              </v-row>
                              </v-form>
                            </v-container>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sectionComplete"
                            >
                              Submit
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                               <v-card
                          v-if="add_vendor_email === 'No'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                                 <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sectionComplete"
                            >
                              Save
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                             </v-expand-transition>
                           </v-dialog>

                            </v-row>
                        </v-container>
                </v-form>
                </base-material-card>

              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.column_53"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_54"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.column_55"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_56"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection()">Save</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>

            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import axios from "axios";

export default {
  name:'Section2',
  data() {
    return {
      home_id: localStorage.getItem("homeid"),
      alert:false,
      dialog: null,
      valid: null,
      section_id: "2",
      staff_email: 'steve@skelleymarketing.com',
      add_vendor_email:'',
      vendor_email: [],
      selected_vendor: {
          ItemId:0,
          Vendor: '',
          Name:'',
          Email: '',
          ModifiedDate:''
        },
      default_vendor: {
          ItemId:0,
          Vendor: '',
          Name:'',
          Email: '',
          ModifiedDate:''
        },
      price_section_two: {
        column_8: '',
        column_9: '',
        column_10: ''
      },
      section: {
        column_1:'',
        column_2:'',
        column_3:'',
        column_4:'',
        column_5:'',
        column_6:'',
        column_7:'',
        column_8:'',
        column_9:'',
        column_10:'',
        column_11:'',
        column_12:'',
        column_13:'',
        column_14:'',
        column_15:'',
        column_16:'',
        column_17:'',
        column_18:'',
        column_19:'',
        column_20:'',
        column_21:'',
        column_22:'',
        column_23:'',
        column_24:'',
        column_25:'',
        column_26:'',
        column_27:'',
        column_28:'',
        column_29:'',
        column_73:'',
        column_30:'',
        column_31:'',
        column_32:'',
        column_33:'',
        column_34:'',
        column_35:'',
        column_36:'',
        column_37:'',
        column_38:'',
        column_39:'',
        column_40:'',
        column_41:'',
        column_42:'',
        column_43:'',
        column_44:'',
        column_45:'',
        column_46:'',
        column_47:'',
        column_48:'',
        column_49:'',
        column_50:'',
        column_51:'',
        column_52:'',
        column_53:'',
        column_54:'',
        column_55:'',
        column_56:'',
        column_57:'',
        column_58:'',
        column_59:'',
        column_60:'',
        column_61:'',
        column_62:'',
        column_63:'',
        column_64:'',
        column_65:'',
        column_66:'',
        column_67:'',
        column_68:'',
        column_69:'',
        column_70:'',
        column_71:'',
        column_72:'',
      },
    selected_user: {
            PrimaryEmailAddress:'',
            SecondaryEmailAddress:'',
            PrimaryFirstName: '',
            PrimaryLastName: '',
            SecondaryFirstName: '',
            SecondaryLastName: '',
            Address:'',
            City:'',
            State: '',
            Zip: '',
            BuildStart: '',
            BuildEnd:'',
            CellNumber:'',
            HomeNumber: '',
            WorkPhone:'',
            Role:'',
            Agent:''
		},
    }
  },
  created () {
    this.getHomePrice()
    this.getVendorEmail()
    this.getUserInfo()
    this.getSection()
    },
    methods:{

        getSection() {
            axios
            .get(process.env.VUE_APP_GET_SECTION_TWO_URL,
            {
            params: {
                home_id: this.home_id,
                section_id: this.section_id
            }
            })
            .then(response => {
                const { data } = response 
                if ( data ) {
                    this.section = JSON.parse(data['JsonDetails'])
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
        getUserInfo() {
            axios
            .get(process.env.VUE_APP_GET_USER_URL_INFO,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {
                const { data } = response
                if ( data ) {
                    this.selected_user = data[0]
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
         getHomePrice(){
            axios
            .get(process.env.VUE_APP_GET_HOME_SECTION_PRICES_URL,
            {
              params: {
                home_id: this.home_id
              }
            })
            .then(response => {
                const { data } = response
                if ( data ) {
                    this.price_section_two = JSON.parse(data['SectionTwo']) || {}
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
        getVendorEmail () {
          axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL)
          .then(response => {
            const { data } = response
            this.vendor_email = data
          })
          .catch(error => {
            console.log(error)
          })
        },
        updateSection(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }
            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
            .then(() => {
                this.alert = true
            })
            .catch(error => {
            console.log(error)
            })
        },
        sectionComplete(){
        var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
        }
        const theNewContent = {
            homeOwner: {
                ...this.selected_user
            },
            sectionData: [
                {
                    Title:"Date",
                    Value: this.section.column_1
                },
                {
                    Title:"Revision #1 Date",
                    Value: this.section.column_2
                },
                {
                    Title:"Revision #2 Date",
                    Value: this.section.column_3
                },
                { 
                    Title:"Revision #3 Date",
                    Value: this.section.column_4
                },
                { 
                    Title:"Additional Information Notes",
                    Value: this.section.column_5
                },
                { 
                    Title:"Optional Doors",
                    Value: this.section.column_6
                },
                { 
                    Title:"Style",
                    Value: this.section.column_7
                },
                { 
                    Title:"Closet Doors Bi Fold",
                    Value: this.section.column_8
                },
                { 
                    Title:"Closet Doors Optional Swing",
                    Value: this.section.column_9
                },
                { 
                    Title:"Double French Doors",
                    Value: this.section.column_10
                },
                { 
                    Title:"Locations",
                    Value: this.section.column_11
                },
                { 
                    Title:"Stainable",
                    Value: this.section.column_12
                },
                { 
                    Title:"Paint Grade",
                    Value: this.section.column_13
                },
                { 
                    Title:"Glass With Grids",
                    Value: this.section.column_14
                },
                { 
                    Title:"Pantry Door Kit",
                    Value: this.section.column_15
                },
                { 
                    Title:"Interior Millwork Package Standard Popular",
                    Value: this.section.column_16
                },
                { 
                    Title:"Optional Casing Main Lvl",
                    Value: this.section.column_17
                },
                { 
                    Title:"Optional Base Main Lvl",
                    Value: this.section.column_18
                },
                { 
                    Title:"Optional Casing Upstairs",
                    Value: this.section.column_19
                },
                { 
                    Title:"Optional Base Upstairs",
                    Value: this.section.column_20
                },
                { 
                    Title:"Optional Casing Basement",
                    Value: this.section.column_21
                },
                { 
                    Title:"Optional Base Basement",
                    Value: this.section.column_22
                },
                { 
                    Title:"Optional Door Toppers Main Lvl",
                    Value: this.section.column_23
                },
                { 
                    Title:"Optional Door Toppers Upstairs",
                    Value: this.section.column_24
                },
                { 
                    Title:"Optional Door Toppers Basement",
                    Value: this.section.column_25
                },
                { 
                    Title:"Balusters Iron Hallow",
                    Value: this.section.column_26
                },
                { 
                    Title:"Balusters Optional",
                    Value: this.section.column_27
                },
                { 
                    Title:"Balusters Wood",
                    Value: this.section.column_28
                },
                { 
                    Title:"Balusters Optional Wood",
                    Value: this.section.column_29
                },
                { 
                    Title:"Newel Post Std Wood",
                    Value: this.section.column_73
                },
                { 
                    Title:"Newel Post Optional",
                    Value: this.section.column_30
                },
                { 
                    Title:"Exterior Front Door Std Kwikset",
                    Value: this.section.column_31
                },
                { 
                    Title:"Exterior Front Door Optional",
                    Value: this.section.column_32
                },
                { 
                    Title:"Interior Std Kwikset",
                    Value: this.section.column_33
                },
                { 
                    Title:"Interior Std Kwikset Optional",
                    Value: this.section.column_34
                },
                { 
                    Title:"Garage To House Std Kwikset",
                    Value: this.section.column_35
                },
                { 
                    Title:"Garage Service Door",
                    Value: this.section.column_36
                },
                { 
                    Title:"NA",
                    Value: this.section.column_37
                },
                { 
                    Title:"Door Hinge Std",
                    Value: this.section.column_38
                },
                { 
                    Title:"Door Hinge Optional",
                    Value: this.section.column_39
                },
                { 
                    Title:"Closet Shelving Std Melamine Shelving",
                    Value: this.section.column_40
                },
                { 
                    Title:"Linen Closet Shelving Std Melamine Shelving",
                    Value: this.section.column_41
                },
                { 
                    Title:"Walk In Pantry Shelving Std Melamine Shelving",
                    Value: this.section.column_42
                },
                { 
                    Title:"Shelving Notes",
                    Value: this.section.column_43
                },
                { 
                    Title:"Switch Plates Outlet Covers",
                    Value: this.section.column_44
                },
                { 
                    Title:"Space Saver Microwave",
                    Value: this.section.column_58
                },
                { 
                    Title:"Dryer Std Electric",
                    Value: this.section.column_45
                },
                { 
                    Title:"Dryer Gas Optional",
                    Value: this.section.column_46
                },
                { 
                    Title:"Stove Std Electric",
                    Value: this.section.column_47
                },
                { 
                    Title:"Stove Gas Optional",
                    Value: this.section.column_48
                },
                { 
                    Title:"Washing Machine Location",
                    Value: this.section.column_49
                },
                { 
                    Title:"Load",
                    Value: this.section.column_50
                },
                { 
                    Title:"ALLOWANCE",
                    Value: this.section.column_51
                },
                { 
                    Title:"Notes",
                    Value: this.section.column_52
                },
                { 
                    Title:"HomeOwner #1",
                    Value: this.section.column_53
                },
                { 
                    Title:"HomeOwner #2",
                    Value: this.section.column_55
                },
                { 
                    Title:"Date Signed",
                    Value: this.section.column_56
                },
            ],
            vendorData: {
                ...this.selected_vendor
            }
        }

        axios
        .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
        .then(() => {
            this.alert = true
            
            if (this.add_vendor_email[0] === 'Y')
            this.sendEmail([this.selected_vendor.Email.Email,this.staff_email],this.staff_email,"vendor_section_submit_notification",theNewContent, this.section_id)
            })
            
        .catch(error => {
            console.log(error)
        })
        this.add_vendor_email = Object.assign({}, this.add_vendor_email)
        this.editedIndex = -1
        this.close()
        },
        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.add_vendor_email = Object.assign({}, this.add_vendor_email)
            this.editedIndex = -1
          })
        },
        sendEmail(to,from,template,content,section){
        var email_body = {
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "section": section
          }
        }

        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(response => {
            const { data } = response
            console.log(data)
        })
        .catch(error => {
            console.log(error)
        })
      }
    },
    computed:{
      homeowner_emailid(){
        return this.selected_user.PrimaryEmailAddress
      },
    permissions(){
    return {scope: localStorage.getItem("permissions")}
    }
  }
}
</script>